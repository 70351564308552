<template>
    <v-app>
        <div class="flex-row-fluid d-flex flex-column position-relative p-14 overflow-y-scroll">
            <div class="d-flex flex-column-fluid flex-center mt-lg-10 mt-lg-0">
                <div class="d-flex flex-column align-items-center align-center text-center">
                    <h1>Forgot Enrolment PIN ?</h1>
                    <v-row>
                        <v-col md="8" v-if="successMessage">
                            <v-alert class="mt-2 text-center "  :icon="false" dense type="success">
                                Your {{forget_enrolment_pin ? 'Enrolment PIN' : 'Enrolment No' }} has been emailed to you.<br>
                            </v-alert>
                        </v-col>
                        <v-col md="12" v-if="errorStatus">
                            <v-alert class="mt-2 text-center "  :icon="false" dense type="error">
                                {{errorMessage}}
                                <br>
                            </v-alert>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field label="Email address" v-model.trim="enrolment.email" outlined :error="$v.enrolment.email.$error"
                                          hide-details>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.enrolment.email.$error">This information is required.</span>
                        </v-col>
                        <v-col cols="12" v-if="forget_enrolment_pin">
                            <v-text-field label="Enrolment No" v-model.trim="enrolment.symbol_no" outlined :error="$v.enrolment.symbol_no.$error"
                                          hide-details>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.enrolment.symbol_no.$error">This information is required.</span>
                        </v-col>
                        <v-col cols="12" v-if="!forget_enrolment_pin">
                            <v-text-field label="Enrolment PIN" v-model.trim="enrolment.enrolment_pin" outlined :error="$v.enrolment.enrolment_pin.$error"
                                          hide-details>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.enrolment.enrolment_pin.$error">This information is required.</span>
                        </v-col>
                        <a @click="changeEnrolmentCredentials" class="text-dark-60 text-hover-primary my-3 mr-2" id="kt_login_forgot">Forgot {{forget_enrolment_pin ? ' Enrolment No' : ' Enrolment PIN'}}
                        </a>
                        <v-col cols="12" class="text-right">
                            <v-btn @click.prevent="redirectTo" depressed class=" btn btn-standard ml-2" dark>
                                Back
                            </v-btn>
                            <v-btn :loading="isLoading" @click.prevent="sendEnrolmentPin" depressed class="btn btn-primary btn-continue ml-2" dark>
                                {{!forget_enrolment_pin ? 'Email Enrolment No ' : 'Email Enrolment PIN'}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
        <vue-recaptcha
            ref="invisibleRecaptcha"
            :sitekey="sitekey"
            @verify="onCaptchaVerified"
            :loadRecaptchaScript="true"
            size="invisible"
        ></vue-recaptcha>
    </v-app>
</template>
<script>
import EssentialService from "@/core/services/enrollment/EssentialService";
import {required, email,requiredIf} from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";
const essential=new EssentialService();
export default {
    components: {VueRecaptcha},
    validations:{
        enrolment:{
            email:{required},
            symbol_no: {
                required: requiredIf(function(nestedModal){
                    return (this.forget_enrolment_pin);
                })
            },
            enrolment_pin: {
                required: requiredIf(function(nestedModal){
                    return !this.forget_enrolment_pin;
                })
            },
        }
    },
    data(){
        return{
          isLoading:false,
          forget_enrolment_pin:true,
          enrolment:{
              email:null,
              symbol_no:null,
              enrolment_pin:null,
          },
            successMessage:false,
            errorMessage:false,
            errorStatus:false,
        }
    },
    methods:{
        changeEnrolmentCredentials(){
            this.$v.$reset();

            if(this.forget_enrolment_pin){
              this.forget_enrolment_pin=false;
              this.enrolment.symbol_no=null;
              this.enrolment.enrolment_pin=null;
          }else{
                this.forget_enrolment_pin=true;
                this.enrolment.symbol_no=null;
                this.enrolment.enrolment_pin=null;
            }


        },
        redirectTo(){
            this.$router.push({name: 'results'});
        },
        onCaptchaVerified(){
            this.$v.$touch()
            if (this.$v.$error) {
                setTimeout(() => {
                    this.$v.$reset()
                }, 50000);
            } else {
                this.enrolment.forget_enrolment_pin=this.forget_enrolment_pin;
                essential
                    .requestEnrolmentCredentials(this.enrolment)
                    .then(response => {
                        if(response.data.status=='OK'){
                            this.successMessage=true;
                            setTimeout(() => {
                                this.successMessage=false;
                                this.enrolment={
                                    email:null,
                                    symbol_no:null,
                                    enrolment_pin:null,
                                };
                                this.$v.$reset()
                            }, 3000);
                        }
                        if(response.data.status=='ERROR'){
                            this.errorStatus=true;
                            this.errorMessage=response.data.message;
                            setTimeout(() => {
                                this.errorStatus=false;
                                this.errorMessage=null;
                            },3000);
                        }



                    })
                    .catch((err) => {

                    })
                    .finally(()=>{

                    });
            }
        },
        sendEnrolmentPin(){
            this.$refs.invisibleRecaptcha.execute();
        },

    },
    computed:{
        sitekey :function () {
            return this.$store.getters.siteKey;
        },
    }
}
</script>